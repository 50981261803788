<template src="./Commercial.html"></template>

<script>
import { mapGetters } from "vuex";
import ArrobaMedellinLogo from "@/components/Icons/ArrobaMedellinLogo";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import { roleName } from "@/utils/constants";
import commercialService from "@/services/Commercial/Commercial";
import tutorial from "@/mixins/tutorial";

export default {
  name: "home",
  mixins: [tutorial],
  components: { ArrobaMedellinLogo, ArrobaMedellinArrobitaNoData },
  data() {
    return {
      userRole: null,
      roles: roleName,
      repeat: false,
      iconSize: "is-medium",
      games: {
        data: [
          {
            image:
              "https://firebasestorage.googleapis.com/v0/b/arrobamedellin.appspot.com/o/imageGames%2Fmanejodelainformaci%C3%B3n.png?alt=media&token=19301681-0679-4f56-a4d6-6d137208b34b",
            descriptionImage: "Imagen juego de Comunicación asertiva",
            title: "Conversando",
            paragraph:
              "Debate con tus amigos sobre las noticias de actualidad en el mundo.",
            lesson:
              "Maneja información con las personas de tu entorno teniendo una comunicación efectiva.",
            bestPoints: 0,
            time: 0,
            titleButton: "Jugar",
            nameId: "assertiveCommunication",
          },
          {
            image:
              "https://firebasestorage.googleapis.com/v0/b/arrobamedellin.appspot.com/o/imageGames%2FgestionEfectivaDelTiempo.png?alt=media&token=4808a689-36d6-4841-827a-85e2a1214e70",
            descriptionImage: "Imagen del juego Gestión efectiva del tiempo",
            title: "Contra el tiempo",
            paragraph:
              "Ayuda a Caspian a cumplir los pendientes que tiene para poder entregar su proyecto a tiempo y no alcance a cruzar el deadline.",
            lesson:
              "Aprende a organizar tus obligaciones para entregarlas a tiempo y alcances una gestión efectiva del mismo.",
            bestPoints: 0,
            time: 0,
            titleButton: "Jugar",
            nameId: "effectiveTimeManagement",
          },
          {
            image:
            "https://firebasestorage.googleapis.com/v0/b/arrobamedellin.appspot.com/o/imageGames%2Fanalisislogico.png?alt=media&token=3c24e5bb-7dd9-4f7b-b0e1-b650e134fb27",
            descriptionImage: "Juego Análisis lógico",
            title: "Enigma",
            paragraph:
              "Desafía tus capacidades de lógica y análisis descifrando los desafíos que se presentan en tu camino.",
            lesson: "Mejora tu desarrollo cognitivo.",
            bestPoints: 0,
            time: 0,
            titleButton: "Descargar",
            gameWebgl:
            "https://firebasestorage.googleapis.com/v0/b/arrobamedellin.appspot.com/o/Games%2FEnigma.zip?alt=media&token=91bb6145-a0b5-4a38-9a63-0d9eee71f9cd",
          },
          {
            image:
              "https://firebasestorage.googleapis.com/v0/b/arrobamedellin.appspot.com/o/imageGames%2Ftrabajoenequipo.png?alt=media&token=8df7b5eb-b688-4022-b75a-f0763d574ce0",
            descriptionImage: "Imagen  del juego Trabajo en equipo",
            title: "El magnate",
            paragraph:
              "Resuelve los desafíos que encontrarás en el camino junto a tu equipo para que Frost, el dueño de la mansión, los contrate como su equipo de seguridad.",
            lesson:
              "Comprende la importancia de trabajar en equipo en cualquier área de tu vida.",
            bestPoints: 0,
            time: 0,
            titleButton: "Descargar",
            gameWebgl:
              "https://firebasestorage.googleapis.com/v0/b/arrobamedellin.appspot.com/o/Games%2FElMagnate.zip?alt=media&token=da31c612-b814-48ec-b504-a33efa965d1e",
          },
          {
            image:
              "https://firebasestorage.googleapis.com/v0/b/arrobamedellin.appspot.com/o/imageGames%2Fhabilidadesdenegociaci%C3%B3n.png?alt=media&token=a8bfd3c1-2255-4b6b-b886-f3e5ad108151",
            descriptionImage: "Imagen del juego Habilidades de negociación",
            title: "Fuga en prisión",
            paragraph:
              "Habla con los reos y conoce qué costo tiene su libertad. Ayúdalos a salir de prisión y gana tu recompensa por el proceso.",
            lesson: "Analiza y construye tus habilidades de negociación.",
            bestPoints: 0,
            time: 0,
            titleButton: "Descargar",
            gameWebgl:
              "https://firebasestorage.googleapis.com/v0/b/arrobamedellin.appspot.com/o/Games%2FFugaEnPrision.zip?alt=media&token=8c3279f6-fb3d-4a44-be16-b4f6abdcd3bb",
          },
        ],
        count: 5,
      },
      fiveGames: [],
      pagination: {
        count: 0,
        initial: 0,
        total: 0,
      },
    };
  },
  created() {
    this.find();
  },
  mounted() {
    this.userRole = this.user.role.name;
    this.dataTutorial("entertainment");
  },
  methods: {
    redirectTo(section) {
      this.$router.push({ name: "ComercialStore", params: { section } });
    },
    async find() {
      this.fiveGames = [];
      let result;
      try {
        result = this.games;
        if (this.pagination.initial === 0) {
          this.fiveGames.push(result.data[0], result.data[1]);
          const assertiveCommunication = "assertiveCommunication";
          const gameAssertiveCommunication =
            await commercialService.getScoreAndTimeGame(assertiveCommunication);
          this.fiveGames[0].bestPoints = gameAssertiveCommunication.bestPoints;
          this.fiveGames[0].time = this.secondsToString(
            gameAssertiveCommunication.time
          );
          const effectiveTimeManagement = "effectiveTimeManagement";
          const gameEffectiveTimeManagement =
            await commercialService.getScoreAndTimeGame(
              effectiveTimeManagement
            );
          this.fiveGames[1].bestPoints = gameEffectiveTimeManagement.bestPoints;
          this.fiveGames[1].time = this.secondsToString(
            gameEffectiveTimeManagement.time
          );
        } else if (this.pagination.initial === 1) {
          this.fiveGames.push(result.data[2], result.data[3]);
          const logicalAnalysis = "logicalAnalysis";
          const gameLogicalAnalysis =
            await commercialService.getScoreAndTimeGame(logicalAnalysis);
          this.fiveGames[0].bestPoints = gameLogicalAnalysis.bestPoints;
          this.fiveGames[0].time = this.secondsToString(
            gameLogicalAnalysis.time
          );
          const teamwork = "teamwork";
          const gameTeamwork = await commercialService.getScoreAndTimeGame(
            teamwork
          );
          this.fiveGames[1].bestPoints = gameTeamwork.bestPoints;
          this.fiveGames[1].time = this.secondsToString(gameTeamwork.time);
        } else {
          this.fiveGames.push(result.data[4]);
          const negotiationSkills = "assertiveCommunication";
          const gameNegotiationSkills =
            await commercialService.getScoreAndTimeGame(negotiationSkills);
          this.fiveGames[0].bestPoints = gameNegotiationSkills.bestPoints;
          this.fiveGames[0].time = this.secondsToString(
            gameNegotiationSkills.time
          );
        }
        this.pagination.count = result.count;
        this.pagination.total = Math.ceil(this.pagination.count / 2);
        if (this.fiveGames.length === 0) {
          this.pagination.initial = 0;
        }
      } catch (error) {
        console.log(error);
      }
    },
    secondsToString(seconds) {
      var hour = Math.floor(seconds / 3600);
      hour = hour < 10 ? "0" + hour : hour;
      var minute = Math.floor((seconds / 60) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      var second = seconds % 60;
      second = second < 10 ? "0" + second : second;
      return hour + ":" + minute + ":" + second;
    },
    ir(n) {
      this.pagination.initial = n;
      this.find();
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    roleValidationNoAccess() {
      return [
        "Invited",
        "University",
        "AdministratorCall",
        "Entrepreneur",
        "Administrator",
      ].some((a) => a === this.userRole);
    },
  },
};
</script>

<style lang="scss" scoped src="./Commercial.scss"></style>
